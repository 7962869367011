import { createContext, useState, useEffect } from 'react';

const NevigationProviderContex = createContext();

function NevigationProvider({children}){

    const [currentPath, setCurrentPath] = useState(window.location.pathname);

    useEffect(()=>{
        const handler = () => {
            setCurrentPath(window.location.pathname);
        }

        window.addEventListener('popstate', handler);


        return () => {
            window.removeEventListener('popstate', handler);
        }
    }, []);


    const nevigate = (to) => {
        window.history.pushState({}, '', to);
        setCurrentPath(to);
    }

    return(
        <NevigationProviderContex.Provider value={{currentPath, nevigate}}>
            { children } 
        </NevigationProviderContex.Provider>
    );
}

export {NevigationProvider};
export default NevigationProviderContex;

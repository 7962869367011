import { useEffect, useState } from 'react';
import './Service.css'


import {ReactComponent as WebDesign} from '../../Utils/img/custom-web-design.svg';
import {ReactComponent as WebDevelopment} from '../../Utils/img/web_development.svg';
import {ReactComponent as WebTesting} from '../../Utils/img/Web-Testing.svg';
import {ReactComponent as WebUpdate} from '../../Utils/img/Web-Update.svg';
import {ReactComponent as UIAutomation} from '../../Utils/img/ui-automation-1.svg';

function Service(){

    useEffect(()=>{
        return () => {
            document.body.style.overflow="auto";
        }
    }, [])

    const [serviceModal, setserviceModal] = useState({
        isVisible: false,
        serviceImage: null,
        serviceName: null,
        description: null,
        why: null
    })

    function handleServiceModal(service) {
        document.body.style.overflow="hidden"
        setserviceModal((prevState) => ({ 
            ...prevState,
            isVisible: true,
            serviceName: service.name,
            description: service.description,
            serviceImage: service.svg,
            why: service.why
        }));
    }

    function handleServiceModalClose() {
        document.body.style.overflow="auto"
        setserviceModal({
            isVisible: false,
            serviceImage: null,
            serviceName: null,
            description: null,
            why: null
        });
    }

    const services = [
        {
            name: 'UI/UX Design',
            isAvailable: true,
            description: 'UI/UX Design refers to the process of designing and optimizing how users interact with a product, such as a website, mobile app, or software application. It focuses on creating interfaces that are visually appealing, easy to use, and enhance user satisfaction.',
            svg: <WebDesign className='service-svg-style' />,
            why: <>
                    <li>Enhances user satisfaction and loyalty.</li>
                    <li>Improves accessibility for a diverse audience.</li>
                    <li>UI/UX focuses on understanding user needs and behaviors, ensuring the product evolves with its audience.</li>
                    <li>A product with great UI/UX reflects positively on the brand, making it stand out as trustworthy and user-centered.</li>
                    <li>Identifying design issues early in the UI/UX phase reduces the cost of fixing problems later during development or after launch.</li>
                    <li>In a crowded market, outstanding UI/UX differentiates a product from its competitors.</li>
                </>
        }, 
        {
            name: 'Website Development',
            isAvailable: true,
            description: 'Website development involves creating and maintaining websites, combining technical expertise, creativity, and strategic planning. It encompasses several stages, including designing the layout, writing code, integrating functionalities, and ensuring the site is user-friendly and responsive across devices. Modern website development often includes back-end programming for data management, front-end development for visual elements, and optimization techniques for performance and security.',
            svg: <WebDevelopment className='service-svg-style' />,
            why: <>
                <li>Websites ensure your business is accessible to audiences 24/7, allowing customers to explore your products or services anytime, anywhere.</li>
                <li>A well-designed website conveys professionalism, builds brand reputation, and positions your business as a reliable player in the market.</li>
                <li>Modern web development allows easy scalability and updates, ensuring your site evolves with your business and technological trends.</li>
                <li>Websites enable businesses to track user behavior, analyze traffic, and make data-driven decisions to optimize performance and strategy.</li>
                <li>In today’s competitive market, having a website is essential to remain relevant and compete effectively with others in your industry.</li>
                <li>A professionally developed website is often the first interaction customers have with your business, setting the tone for trust and credibility.</li>
                <li>Effective web development ensures seamless navigation, fast load times, and mobile responsiveness, keeping users engaged and satisfied.</li>

            </>
        },{
            name: 'Mobile App Development',
            isAvailable: true,
            description: 'Mobile app development is the process of creating software applications specifically designed to run on mobile devices such as smartphones and tablets. These apps are tailored to offer seamless functionality, intuitive interfaces, and an engaging user experience on platforms like Android and iOS.',
            svg: <WebDevelopment className='service-svg-style' />,
            why: <>
                <li>Mobile apps provide a direct and personalized channel for businesses to interact with their customers.</li>
                <li>Apps allow users to access your products or services anytime, anywhere, boosting convenience and satisfaction.</li>
                <li>A well-designed mobile app reinforces brand presence and helps establish trust among users.</li>
                <li>In today’s digital era, having a mobile app gives your business an advantage over competitors without one.</li>
                <li>Mobile apps can drive sales through in-app purchases, ads, or seamless e-commerce experiences.</li>
                <li>Apps provide valuable insights into customer behavior and preferences, enabling data-driven decision-making.</li>
                <li>Mobile apps can scale with your business, allowing you to reach new markets and audiences effectively.</li>
                <li>Custom apps can enhance internal efficiency, automating tasks and improving productivity.</li>
                <li>With the increasing use of smartphones, having a mobile app is essential to stay relevant in the market.</li>
            </>
        }, 
        {
            name: 'Update Your Website',
            isAvailable: true,
            description: 'By keeping your website up to date, you maintain a competitive edge, protect against security vulnerabilities, and provide users with the seamless experience they expect. Whether it’s minor tweaks or a complete overhaul, website updates are vital for staying relevant and growing your digital presence effectively.Regular updates ensure your site stays visually appealing, mobile-responsive, and optimized for performance. It can involve redesigning outdated layouts, integrating modern features, improving load speeds, and implementing the latest SEO practices.',
            svg: <WebUpdate className='service-svg-style' />,
            why:<>
                <li>Search engines prioritize updated websites with fresh content, better speed, and modern SEO practices, improving your search rankings.</li>
                <li>Updating your website helps protect against vulnerabilities, ensuring your site stays secure from cyber threats and data breaches.</li>
                <li>Fresh content keeps your audience engaged and improves your credibility, while outdated information can turn users away.</li>
                <li>Regular updates ensure your website remains user-friendly, with intuitive navigation, fast load times, and responsive design.</li>
                <li>A well-maintained, up-to-date website signals professionalism and commitment to providing users with the best experience.</li>
            </>
        },
        {
            name: 'Website Testing',
            isAvailable: true,
            description: 'Website testing is a critical process to ensure your site functions smoothly, delivers a seamless user experience, and meets quality standards before going live. It involves evaluating various aspects of the website, such as functionality, performance, security, compatibility, and usability.Key areas tested include checking for broken links, verifying responsiveness across devices, ensuring cross-browser compatibility, optimizing load times, and identifying potential vulnerabilities. By performing thorough testing, you can resolve issues early, improve user satisfaction, and maintain your brand’s credibility. A well-tested website not only enhances performance but also ensures it meets business goals and user expectations effectively.',
            svg: <WebTesting className='service-svg-style'/>,
            why:<>
                <li>A well-tested website with fast performance and no errors improves your search engine ranking and visibility.</li>
                <li>Detecting and fixing issues before launch prevents potential downtime or functionality problems that could lead to lost customers or revenue.</li>
                <li>Testing prepares your website for handling higher traffic volumes, ensuring it remains stable as your audience grows.</li>
                <li>Testing identifies and resolves bugs, broken links, or errors in navigation, ensuring your website functions as intended.</li>
                <li>Cross-browser testing guarantees consistent functionality and design across all major web browsers.</li>
                <li>Testing ensures your website adapts seamlessly to different devices and screen sizes, crucial in today’s mobile-first world.</li>
                <li>A smooth, error-free website enhances usability and keeps visitors engaged, reducing bounce rates.</li>

            </>
        },
        {
            name: 'UI Automation',
            isAvailable: true,
            description: 'UI automation streamlines the process of testing user interfaces by using scripts and tools to mimic user interactions with an application. It involves automating tasks like clicking buttons, entering data, and navigating through workflows to ensure the UI behaves as expected across different scenarios.This approach is essential for improving testing efficiency, reducing human error, and ensuring consistency. By automating repetitive tasks, teams can focus on more complex testing and development, speeding up release cycles. UI automation is particularly valuable for regression testing, cross-browser compatibility checks, and performance monitoring, ensuring your application delivers a seamless user experience at scale.',
            svg: <UIAutomation className='service-svg-style' />,
            why:<>
                <li>Automating repetitive UI tests saves time and effort, enabling faster testing cycles compared to manual testing.</li>
                <li>Automation handles large-scale, repetitive tasks efficiently, supporting complex testing requirements as applications grow.</li>
                <li>Automation eliminates the risk of human error, ensuring consistent and reliable results across test runs.</li>
                <li>UI automation allows for quick and thorough regression testing whenever code changes are made, ensuring new updates don't break existing functionality.</li>
                <li>Automated tests can be run across multiple browsers and devices simultaneously, verifying a consistent user experience.</li>
                <li>Automation enables rapid feedback on UI functionality, allowing development teams to address issues earlier in the development process.</li>
                <li>Automation allows for extensive test coverage by running more tests in less time, ensuring all aspects of the UI are validated.</li>
                <li>Automated UI tests seamlessly integrate into CI/CD pipelines, ensuring rapid and reliable deployments.</li>
            </>
        }
    ]

    const renderItems = services.map((service, index) => {
        return (
            <div key={index} className="service" onClick={() => handleServiceModal(service)}>
                    <h3>{service.name}</h3>
                    <div>{service.svg}</div>
            </div>
        );
    })
    
    return(
        <div className='service-root-container'>
            <div className='service-container'>
                {renderItems}
            </div>

            { serviceModal.isVisible ? 
                <div className='service-modal'>
                    <div className='service-modal-content'>
                        <div className='service-modal-header'><h1>{serviceModal.serviceName}</h1><span onClick={handleServiceModalClose}>X</span></div>
                        <div className='service-description'>
                            <div>{serviceModal.serviceImage}</div>
                            <p>{serviceModal.description}</p>
                        </div>    

                        <button onClick={() => window.location.href='/get-in-touch'} style={{backgroundColor: 'green'}} className='get-quote'>Let's Connect</button>
                        <button onClick={() => window.location.href='/price'} className='get-quote'>Get Your Quotation</button>

                        <h1 className='why-ui-ux'>Why Is {serviceModal.serviceName} Important?</h1>
                        <ul>
                            {serviceModal.why}
                        </ul>
                    </div>
                </div> : ''}
        </div>	
    );
}
export default Service;

import './WelcomePage.css'
import Idea from '../../../Utils/img/website-setup.png';
import { useEffect } from 'react';

function WelcomePage(){
    useEffect(() => {
        const textArray = ["With Precision", "With Innovation", "With Integrity"]
        let textPosition = 0;
        let arrayIndex = 0;
        const speed = 100; 
        let isMounted = true;

        function typewriter() {
            if (!isMounted) return;
            const textElement = document.querySelector(".quotes-text");

            if (textPosition < textArray[arrayIndex].length) {
                textElement.innerHTML += textArray[arrayIndex].charAt(textPosition);
                textPosition++;
                setTimeout(typewriter, speed);
            } else {
                setTimeout(typeCleaner, 2000); 
            }
        }

        function typeCleaner() {
            if (!isMounted) return;
            const textElement = document.querySelector(".quotes-text");

            if(textPosition >= 0) {
                textElement.innerHTML = textElement.innerHTML.slice(0, -1);
                textPosition--;
                setTimeout(typeCleaner, speed);
            } else {
                arrayIndex = (arrayIndex + 1) % textArray.length;
                setTimeout(typewriter, 2000);
            }
        }

        typewriter();   

        return () => {
            isMounted = false;
        };
    }, []);

    return(
        <div className='welcome-container'>
            <div className='quotes'>
                <h1>Your Vision, Our Commitment</h1>
                <p className='typewriter'>Turning Ideas into Reality<span className='quotes-text'></span></p>
                <button className='contact-us-button' onClick={() => window.location.href='/get-in-touch'}>Contact us</button>
            </div>

            <div className='responsive-design'>
                <img src={Idea} className='responsive-image' alt='responsive-image'/>

                <h2>Custom Web Solutions
                    <svg className="custom-web-solution-border" viewBox="0 0 322 57" preserveAspectRatio="none">
                        <rect x="1" y="1" width="320" height="55" rx="27" ry="27"></rect>
                    </svg>
                </h2>
            </div>

        </div>
    );
}

export default WelcomePage;

import './HomePage.css';

import ChallengesCard from "../HomePage/ChallengesCard/ChallengesCard";
import ConnectsWithUs from "../ConnectsWithUs/ConnectsWithUs";
import GetPrice from "./GetPrice/GetPrice";
import TestiMonial from "../HomePage/TestiMonial/TestiMonial";
import WelcomePage from "../HomePage/WelcomePage/WelcomePage";
import FAQ from '../FAQ/FAQ';

function HomePage(){
    return(<>
        <section className='welcomepage-section'>
            <WelcomePage />
        </section>

        <section className='challengecard-section'>
            <ChallengesCard />
        </section>

        <section className='testimonial-section'>
            <TestiMonial />
        </section>
      
        <section className='get-your-price'>
            <GetPrice />
        </section>

        <section className='connect-with-us-section'>
            <ConnectsWithUs />
        </section>
        
        <section className='faq-section'>
            <FAQ />
        </section>
    </>
    );
}
export default HomePage;

import './FAQ.css';
import { ReactComponent as UpArrow } from '../../Utils/img/uparrow.svg';
import { useState, useContext } from 'react';
import NevigationProviderContex from '../../Utils/Nevigation/NevigationProvider';

function FAQ() {
    const {currentPath} = useContext(NevigationProviderContex)
    const [activeIndex, setActiveIndex] = useState([false, false, false]);

    const toggleFAQ = (index) => {
        setActiveIndex((prevState) => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });
    };

    const faqData = [
        {
            question: "How much does it cost to build a website?",
            answer: "The cost depends on the features and complexity. Contact us with your requirements, and we’ll provide a free quote."
        }, 
        {
            question: "Will my website be mobile-friendly?",
            answer: "Yes, we ensure your website looks and works great on mobile devices, tablets, and desktops."
        },
        {
            question: "How long does it take to build a website?",
            answer: "The time depends on the size and complexity of the website. Simple websites can take 1-2 weeks, while more complex ones may take a month or more."
        },
        {
            question: "Can I update my website after it’s completed?",
            answer: "Yes, we can create an easy-to-use admin panel so you can make updates without technical knowledge."
        }, 
        {
            question: "What is UI/UX development, and why does it matter?",
            answer: "UI (User Interface) focuses on the look of your app or website, while UX (User Experience) focuses on how easy it is to use. Good UI/UX design makes your users happy and improves customer satisfaction."
        }, 
        {
            question: "How do you create a design that matches my business?",
            answer: "We start by understanding your business goals, target audience, and preferences. Based on this, we create designs that reflect your brand."
        }, 
        {
            question: "Can I see the design before development starts?",
            answer: "Yes, we create mockups and prototypes using tools like Figma so you can review and approve the design before development begins."
        }, 
        {
            question: "What if I don’t like the initial design?",
            answer: "No worries! We’ll revise the design based on your feedback until you’re completely happy."
        }, 
        {
            question: "What is software testing, and why is it important?",
            answer: "Software testing checks if your website or app works correctly and has no bugs. It ensures your users have a smooth experience and prevents costly errors after launch."
        }, 
        {
            question: "Do you offer manual or automated testing?",
            answer: "We provide both manual and automated testing, depending on your project needs. Manual testing is great for detailed checks, while automation is best for repetitive tasks."
        }, 
        {
            question: "How long does testing take?",
            answer: "It depends on the size and complexity of the project. Simple testing can take a few days, while larger projects may take a week or more.It depends on the size and complexity of the project. Simple testing can take a few days, while larger projects may take a week or more."
        },
         
        {
            question: "What tools do you use for testing?",
            answer: "We use tools like Selenium, Selenide, and JUnit for automated testing, and manual testing techniques for specific scenarios."
        },
         
        {
            question: "What is UI automation?",
            answer: "UI automation is the process of testing your website or app using scripts instead of manual efforts. It saves time and ensures consistent results."
        },
         
        {
            question: "Why should I use UI automation for my project?",
            answer: "Automation is great for repetitive tasks and ensures your app works perfectly across browsers and devices. It’s faster and more reliable than manual testing for large projects."
        },
         
        {
            question: "Can UI automation find all bugs?",
            answer: "Automation can catch many issues, but some complex bugs may require manual testing for detailed analysis. We combine both methods for the best results."
        },
         
        {
            question: "Why should I choose your services?",
            answer: "We focus on providing high-quality solutions tailored to your business needs. Our experienced team ensures your project is delivered on time and within budget."
        },
         
        {
            question: "How do we communicate during the project?",
            answer: "We provide regular updates through email, video calls, or chat platforms like WhatsApp or Slack, ensuring you’re involved at every stage."
        },
         
        {
            question: "What if I need help after the project is done?",
            answer: "We offer post-launch support and maintenance to make sure your website or app runs smoothly."
        }, 
    ];

    return (
        <div className='faq-container'>
            { currentPath === '/faqs' ? <></> : <h1>Frequently Asked Questions</h1> }
            {faqData.map((faq, index) => (
                <div key={index} className='faq'>
                    <div className='question' onClick={() => toggleFAQ(index)}>
                        <h1>
                        {faq.question} 
                        </h1>
                        <UpArrow className={`arrow ${activeIndex[index] ? 'open':''}`} />
                    </div>
                    <p className={`answer ${activeIndex[index] ? 'open':''}`}>{faq.answer}</p>
                </div>
            ))}
        </div>
    );
}

export default FAQ;

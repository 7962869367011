import './Footer.css'
import {ReactComponent as LOGO} from '../../Utils/img/logo.svg'

function Footer(){
    const links = [
        {label:'Home', to:'/'},
        {label:'Service', to:'/service'},
        {label:'Pricing', to:'/price'},
        {label:'FAQs ', to:'/faqs'},
        {label:'About us', to:'/aboutus'},
    ]

    const renderItems = links.map((link) => {
        return(
            <a
                key={link.label}
                href={link.to}
            >
                {link.label}
            </a>
        );
    });

    return(
        <div className='footer-container-root'>
            <div className='footer-container'>
                <div className='top-content'> 
                    <div className='logo'>
                        <LOGO style={{width:'300px', height:'200px'}}/>
                    </div>
                    <div className='quick-links'>
                        <h1>Quich links</h1>
                        <div>
                            {renderItems}
                        </div>
                    </div>
                    <div className='footer-lets-connect'>
                        <h1>Let's Connect</h1>
                        <div>Mail us on <span>theingenioustech@theingenioustech.com</span></div>
                    </div>
                </div>
                <div className='bottom-content'> 
                    <p>Copyright @ 2024 The Ingenious Tech</p>
                    <p>Powered By The Ingenious Tech (<span>www.theingenioustech.com</span>)</p>
                </div>
            </div>
        </div>
    );
}
export default Footer;

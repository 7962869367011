import './Spinner.css'
import {ReactComponent as SpinnerLogo} from '../../Utils/img/spinner-logo.svg';

function Spinner(){
    return(
        <div className='spinnner-container'>
            <SpinnerLogo className='spinner' />
        </div>
    );
}
export default Spinner;

import './ChallengesCard.css'
import {ReactComponent as CustomeDesign} from '../../../Utils/img/custom-web-design.svg';
import {ReactComponent as WebSecurity} from '../../../Utils/img/web-security.svg';
import {ReactComponent as ResponsiveDesign} from '../../../Utils/img/responsive-design.svg';

function ChallengesCard(){
    const data = [
        "Off-the-shelf templates can’t meet the complex demands of a large organization. We provide fully customized solutions designed to support your unique business processes, ensuring your website not only stands out but also serves as a powerful tool for driving growth.",
        "In today’s digital landscape, security is paramount. We implement industry-leading security measures, data protection protocols, and regular vulnerability assessments to safeguard your website against potential threats, keeping your sensitive information and reputation intact.",
        "Enterprise-level websites must deliver a flawless experience across all platforms. We create responsive designs that adapt seamlessly to any device, enhancing accessibility for your global audience and ensuring no opportunity is missed."
    ]

    return(
        <div className="challenge-container">
            <h1>Are you facing these challenges?</h1>
            <div className='card-container'>
                <div className='card'>
                    <h3 className='header'>Need a Modern, Custom Solution</h3>
                    <p className='description'>{data[0]}</p>
                    <CustomeDesign className='custome-design-svg'/>
                </div>

                <div className='card'>
                    <h3 className='header'>Concerned About Security Threats</h3>
                    <p className='description'>{data[1]}</p>
                    <WebSecurity className='web-security-svg' />
                </div>

                <div className='card'>
                    <h3 className='header'>Struggling with Mobile Responsiveness</h3>
                    <p className='description'>{data[2]}</p>
                    <ResponsiveDesign className='responsive-design-svg'/>
                </div>

            </div>
        </div>
    );
}
export default ChallengesCard;

import { useContext } from "react";
import NavigationProviderContext from "./NevigationProvider";

function Link({to, children}){
    const {nevigate} = useContext(NavigationProviderContext);

    const handleClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
            return;
        }
        
        document.querySelector(".hamburger-link").classList.remove('open');
        
        event.preventDefault();
        nevigate(to);
    }

    return(
        <a onClick={handleClick} href={to} style={{color: 'black', textDecoration: 'none'}}>{children}</a>	
    );
}
export default Link;

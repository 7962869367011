import './AboutUs.css'

import {ReactComponent as Transperent} from '../../Utils/img/Transperency.svg';
import {ReactComponent as Quality} from '../../Utils/img/Quality.svg';
import {ReactComponent as Success} from '../../Utils/img/Success.svg';
import {ReactComponent as Idea} from '../../Utils/img/Idea.svg';
import {ReactComponent as Trust} from '../../Utils/img/Trust.svg';


function AboutUs(){
    return(
        <div className='about-us-container'>
            <div className='aboutus'>
                <h1>About Us</h1>
                <p>The Ingenious Tech is a comprehensive software and web development company dedicated to meeting specific client business requirements. We deliver tailored solutions by leveraging cutting-edge technologies, ensuring the best outcomes to drive innovation and growth.</p>
                <button className='lets-connect' onClick={() => window.location.href='/get-in-touch'}>Let's Connect</button>
            </div>
            <div className='our-core-value'>
                <h1>Our Core Value</h1>

                <div className='core-value'>
                    <div className='value'>
                        <Transperent className='value-svg'/>
                        <p>We uphold the highest standards of honesty and transparency</p>
                    </div>

                    <div className='value'>
                        <Quality className='value-svg' />
                        <p>Delivering exceptional quality in every project</p>
                    </div>

                    <div className='value'>
                        <Success className='value-svg' />
                        <p>Your success is our priority</p>
                    </div>

                    <div className='value'>
                        <Idea className='value-svg' />
                        <p>Bringing fresh ideas and solutions to the table</p>
                    </div>

                    <div className='value'>
                        <Trust className='value-svg' />
                        <p>Trust us to deliver on time, every time</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AboutUs;

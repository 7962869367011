import './NavBar.css'
import logo from '../../Utils/img/logo.svg';
import { useContext } from 'react';
import { ReactComponent as HamBurger } from "../../Utils/img/hamburger.svg";
import Link from '../../Utils/Nevigation/Link';
import NevigationProviderContex from '../../Utils/Nevigation/NevigationProvider';

function NavBar(){
    const {nevigate} = useContext(NevigationProviderContex);

    const links = [
        {label:'Home', to:'/'},
        {label:'Service', to:'/service'},
        {label:'Pricing', to:'/price'},
        {label:'FAQs ', to:'/faqs'},
        {label:'About us', to:'/aboutus'},
    ]

    const handleClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
            return;
        }
        
        event.preventDefault();
        nevigate('/get-in-touch');
    }

    const renderItems = links.map((link) => {
        return(
            <Link
                className="nav-item"
                key={link.label}
                to={link.to}
            >
                {link.label}
            </Link>
        );
    });
    
    const handleHamburgerClick = () => {
        var hamburger = document.querySelector(".hamburger-link");
        hamburger.classList.toggle('open');
    }

    return(
        <nav className='navbar'>
            <img src={logo} className='logo-design' alt='logo' onClick={() => window.location.href='/'}/>
            <ul className='navbar-items'>
                {renderItems}
            </ul>
            <button className='intouch-button' onClick={handleClick}>Get in touch</button>


            <div className='hamburger'>
                <HamBurger className='hamburger-icon' onClick={() => handleHamburgerClick()}/>
                <ul className='hamburger-link'>
                    {renderItems}
                    <Link key={"Get in touch"} to="/get-in-touch">Get in touch</Link>  
                </ul>
            </div>
        </nav>
    );
}
export default NavBar;

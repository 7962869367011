import { useContext } from 'react';
import NevigationProviderContex from './NevigationProvider';

function Route({path, children}){
    const {currentPath} = useContext(NevigationProviderContex)
        
    if(path === currentPath) {
        return children;
    }
    
    return null;
}
export default Route;

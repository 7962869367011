import './GetPrice.css'
import WebDesign from '../../../Utils/img/web-design.png';

function GetPrice(){
    return(
        <div className='get-price-container'>
            <div className='get-price-block'>
                <div className='get-price-quote'>
                    <p>Your brand's first impression starts with a beautifully crafted website.</p>
                    <button onClick={() => window.location.href="/service"}>Check Out Our Service's</button>
                </div>

                <img className='quote-img' alt='quote' src={WebDesign} />
                
            </div>
        </div>
    );
}
export default GetPrice;

import NavBar from './Component/NavBar/NavBar';
import Route from './Utils/Nevigation/Route';
import HomePage from './Component/HomePage/HomePage';
import ConnectsWithUs from './Component/ConnectsWithUs/ConnectsWithUs';
import FAQ from './Component/FAQ/FAQ';
import Service from './Component/Service/Service';
import AboutUs from './Component/AboutUs/AboutUs';
import Price from './Component/Price/Price';
import Footer from './Component/Footer/Footer';

function App() {
  return (<>
      
      <section className='navbar-section'>
        <NavBar />
      </section>

      <section style={{paddingTop:'80px'}}/>

      <Route path='/'><HomePage /></Route>
      <Route path='/get-in-touch'><ConnectsWithUs /></Route>
      <Route path='/faqs'><FAQ /></Route>
      <Route path='/service'><Service /></Route>
      <Route path='/aboutus'><AboutUs /></Route>
      <Route path='/price'><Price /></Route>

      <section style={{paddingTop:'80px'}}/>

      <section className='footer-section'>
        <Footer />
      </section>
    </>
  );
}

export default App;
